.App {
  height:710px;
  display:grid;
  grid-template-columns: 1fr 4fr;
  line-height: 1.3em;
  letter-spacing: 0.04em;
  display: flex;
}
.side{
  min-width:300px;
  z-index: 1;
}
.side.right {
  transform: translate(calc(100vw - 300px));
  transition: 1s ease-in-out;
}
.side.left {
  transform: translate(0);
  transition: 1s ease-in-out;
}
.main{
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url('./components/images/Jon-relaxed.jpg');
  background-size: cover;
  opacity: 0;
  animation: 3s fadeIn 0.5s forwards;
}


@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1;}
}

p{
  color: rgba(0, 0, 0, 0.7);
 font-weight: normal;
}


/*//////////////// Media Query/////////////////*/


@media (max-width:992px){
  .App{
    height:100%;
    display: flex;
    flex-direction: column;
  }
  h2{
    font-weight: bold;
  }
  p{
    font-weight: bolder;
  }
  .main{
    margin-top: -40px;
    overflow-y: hidden;
  }
  .side.right{
    transform: translate(0);
  }
}

@media (max-width:769px){
  .App{
     display: flex;
    flex-direction: column;

  }
  .main{
    overflow: auto;
    overflow-x: hidden;
  }

}
