@import url(https://fonts.googleapis.com/css?family=Amaranth&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:400&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  height:710px;
  display:grid;
  grid-template-columns: 1fr 4fr;
  line-height: 1.3em;
  letter-spacing: 0.04em;
  display: flex;
}
.side{
  min-width:300px;
  z-index: 1;
}
.side.right {
  -webkit-transform: translate(calc(100vw - 300px));
          transform: translate(calc(100vw - 300px));
  transition: 1s ease-in-out;
}
.side.left {
  -webkit-transform: translate(0);
          transform: translate(0);
  transition: 1s ease-in-out;
}
.main{
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url(/static/media/Jon-relaxed.09fbc7c8.jpg);
  background-size: cover;
  opacity: 0;
  -webkit-animation: 3s fadeIn 0.5s forwards;
          animation: 3s fadeIn 0.5s forwards;
}


@-webkit-keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1;}
}


@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1;}
}

p{
  color: rgba(0, 0, 0, 0.7);
 font-weight: normal;
}


/*//////////////// Media Query/////////////////*/


@media (max-width:992px){
  .App{
    height:100%;
    display: flex;
    flex-direction: column;
  }
  h2{
    font-weight: bold;
  }
  p{
    font-weight: bolder;
  }
  .main{
    margin-top: -40px;
    overflow-y: hidden;
  }
  .side.right{
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@media (max-width:769px){
  .App{
     display: flex;
    flex-direction: column;

  }
  .main{
    overflow: auto;
    overflow-x: hidden;
  }

}

.sidebar{
    height:100vh;
    font-family: 'Ubuntu', sans-serif;
    background-color:#f2f3f7;
    text-align: center;
    padding: 0;
}
.sidebar > h1 {
  text-align: center;
}
.sidebar > h2 {
  font-size: 1rem;
}

h1 {
  text-decoration: none;
  color:#3f3f3f;
  font-size:32px;
}
.sidebar > p {
    margin-top: 30px;
    text-align: center;
}

.socials{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}
.sidebar-nav-items{
    margin-bottom: 15px;
}
.sidebar>img{
  height: 100px;
  margin-top: 40px;
  transition: all 0.5s;
} 
.sidebar>img:hover {
  opacity: 0.5;
}
.socials a {
  text-decoration: none;
  font-family: 'Ubuntu', cursive;
  margin: 10px;
  color:#3f3f3f;
  font-size: larger;
  line-height: 1.8rem;
}
.sidebar a:hover {
  color:#2998ff;
}
.sidebar-nav-icons{
  margin-bottom: 15px;
}

.flip-card-back{
  margin-top:50px;
}

.links:hover{
color:rgb(121, 189, 252);
text-decoration: underline;
}
ul{
  list-style: none;
}
 /*//////////////media ////////////////////*/
@media (max-width:980px){
  .sidebar{
    width:106vw;
    height:106vh;
  }
  h1{
    font-size:29px;
  }
}


@media (max-width:768px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size:28px;
  }
  .links{
display:none;
  }
  .fas{
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    margin:5px 0px;
  }
.gmail{
  font-size:16px;  
  font-weight: normal;
}
}

@media (max-width:370px){
  .sidebar{
    width:106vw;
  }
  .h1_links{
    font-size: 1.5rem;
  }
  .links{
    font-size: 1rem;
  }
  .sidebar-nav-icons{
    font-size: 2rem;
    margin:5px 0px;
  }

}

